import { getHeader } from "./getHeader";
import { useGetBodyAndTotalSum } from "./useGetBodyAndTotalSum";

type IdNumber = {
	value: string;
	bodyIndex?: number;
	textIndent?: boolean;
};

export function useGetPdfHeaderAndBody() {
	const getBodyAndTotalSum = useGetBodyAndTotalSum();

	return async ({
		headerIdNumber,
		headerName,
		id_numbers,
		isReverseSum = false,
	}: {
		headerIdNumber: string;
		headerName: string;
		id_numbers: IdNumber[];
		isReverseSum?: boolean;
	}): Promise<{ header: string[]; body: any[][]; totalSum: number }> => {
		const { body, totalSum } = await getBodyAndTotalSum({ id_numbers, isReverseSum });
		const header: any[] = getHeader({ code: headerIdNumber, name: headerName, sum: totalSum });
		return { header, body, totalSum };
	};
}
