import { getNumberCell } from "./getNumberCell";
import { getRatioCell } from "./getRatioCell";

export function getSumRow({ title, sum, totalSum }: { title: string; sum: number; totalSum: number }) {
	return [
		{
			text: title,
			colSpan: 4,
			style: [],
		},
		"",
		"",
		"",
		getNumberCell({ value: sum }),
		getRatioCell({ sum, totalSum }),
	];
}
