import { updateBodyRatio } from "../balanceSheetPdf/updateBodyRatio";
import { getHeader } from "./getHeader";
import { getSumRow } from "./getSumRow";
import { updateHeaderRatio } from "./updateHeaderRatio";
import { useGetBodyAndTotalSum } from "./useGetBodyAndTotalSum";
import { useGetPdfHeaderAndBody } from "./useGetPdfHeaderAndBody";

export function useGetIncomeStatementSheetBodyAndProfitAndLoss() {
	const getPdfHeaderAndBody = useGetPdfHeaderAndBody();
	const getBodyAndTotalSum = useGetBodyAndTotalSum();

	return async (): Promise<{ body: any[]; profitAndLoss: number }> => {
		let body: any[] = [["科目代號", "科目名稱", "金額", "小計", "合計", "比率"]];
		const fourOneHeaderAndBody = await getPdfHeaderAndBody({
			isReverseSum: true,
			headerIdNumber: "4100",
			headerName: "營業收入淨額",
			id_numbers: [
				{
					value: "4101",
				},
				{
					value: "4102",
				},
				{
					value: "4103",
				},
			],
		});
		body.push(fourOneHeaderAndBody.header);
		body.push(...fourOneHeaderAndBody.body);
		const totalSum = fourOneHeaderAndBody.totalSum;
		updateHeaderRatio({ headerData: fourOneHeaderAndBody.header, totalSum });
		updateBodyRatio({ bodyData: fourOneHeaderAndBody.body, totalSum, textIndex: 3, ratioIndex: 5 });

		const fiveOneHeaderAndBody = await getPdfHeaderAndBody({
			headerIdNumber: "5100",
			headerName: "營業成本",
			id_numbers: [
				{ value: "5150" },
				{ value: "5151-001" },
				{ value: "5151-002" },
				{ value: "5151-003" },
				{ value: "5151-004" },
				{ value: "5152" },
				{ value: "5153" },
				{ value: "5154" },
			],
		});
		body.push(fiveOneHeaderAndBody.header);
		body.push(...fiveOneHeaderAndBody.body);
		updateHeaderRatio({ headerData: fiveOneHeaderAndBody.header, totalSum });
		updateBodyRatio({ bodyData: fiveOneHeaderAndBody.body, totalSum, textIndex: 3, ratioIndex: 5 });

		//4100sum - 5100sum
		const operatingGrossProfit = fourOneHeaderAndBody.totalSum - fiveOneHeaderAndBody.totalSum;

		body.push(getSumRow({ title: "營業毛利", sum: operatingGrossProfit, totalSum }));

		const fiveTwoOneZeroToFiveTwoThreeFive = await getBodyAndTotalSum({
			id_numbers: [
				{ value: "5210" },
				{ value: "5211" },
				{ value: "5212" },
				{ value: "5213" },
				{ value: "5214" },
				{ value: "5215" },
				{ value: "5216" },
				{ value: "5216-01" },
				{ value: "5217" },
				{ value: "5218" },
				{ value: "5219" },
				{ value: "5220" },
				{ value: "5222" },
				{ value: "5224" },
				{ value: "5228" },
				{ value: "5230" },
				{ value: "5233" },
				{ value: "5234" },
				{ value: "5235" },
			],
		});
		updateBodyRatio({ bodyData: fiveTwoOneZeroToFiveTwoThreeFive.body, totalSum, textIndex: 3, ratioIndex: 5 });

		const fiveTwoThreeSevenRows = await getBodyAndTotalSum({
			id_numbers: [
				{ value: "5237-001", bodyIndex: 2, textIndent: true },
				{ value: "5237-005", bodyIndex: 2, textIndent: true },
				{ value: "5237-006", bodyIndex: 2, textIndent: true },
				{ value: "5237-007", bodyIndex: 2, textIndent: true },
				{ value: "5237-008", bodyIndex: 2, textIndent: true },
				{ value: "5237-009", bodyIndex: 2, textIndent: true },
				{ value: "5237-012", bodyIndex: 2, textIndent: true },
			],
		});
		updateBodyRatio({ bodyData: fiveTwoThreeSevenRows.body, totalSum, textIndex: 2, ratioIndex: 5 });

		const fiveTwoZeroZeroTotalSum = fiveTwoOneZeroToFiveTwoThreeFive.totalSum + fiveTwoThreeSevenRows.totalSum;
		const fiveTwoZeroZeroHeader = getHeader({
			code: "5200",
			name: "營業費用總額",
			sum: fiveTwoZeroZeroTotalSum,
		});
		updateHeaderRatio({ headerData: fiveTwoZeroZeroHeader, totalSum });
		body.push(fiveTwoZeroZeroHeader);

		body.push(...fiveTwoOneZeroToFiveTwoThreeFive.body);

		const fiveTwoThreeSevenHeader = getHeader({
			code: "5237",
			name: "其他費用",
			sum: fiveTwoThreeSevenRows.totalSum,
		});
		updateHeaderRatio({ headerData: fiveTwoThreeSevenHeader, totalSum });
		body.push(fiveTwoThreeSevenHeader);
		body.push(...fiveTwoThreeSevenRows.body);

		const netProfit = operatingGrossProfit - fiveTwoZeroZeroTotalSum;
		body.push(getSumRow({ title: "營業淨利", sum: netProfit, totalSum }));

		const fiveThreeHeaderAndBody = await getPdfHeaderAndBody({
			headerIdNumber: "5300",
			headerName: "非營業費用總額",
			id_numbers: [],
		});
		body.push(fiveThreeHeaderAndBody.header);
		body.push(...fiveThreeHeaderAndBody.body);
		updateHeaderRatio({ headerData: fiveThreeHeaderAndBody.header, totalSum });

		const fourTwoHeaderAndBody = await getPdfHeaderAndBody({
			isReverseSum: true,
			headerIdNumber: "4200",
			headerName: "非營業收入",
			id_numbers: [{ value: "4201" }, { value: "4204" }, { value: "4210" }],
		});
		body.push(fourTwoHeaderAndBody.header);
		body.push(...fourTwoHeaderAndBody.body);
		updateHeaderRatio({ headerData: fourTwoHeaderAndBody.header, totalSum });
		updateBodyRatio({ bodyData: fourTwoHeaderAndBody.body, totalSum, textIndex: 3, ratioIndex: 5 });

		const profitAndLoss = netProfit + fourTwoHeaderAndBody.totalSum - fiveThreeHeaderAndBody.totalSum;
		body.push(getSumRow({ title: "本期損益", sum: profitAndLoss, totalSum }));

		return { body, profitAndLoss };
	};
}
