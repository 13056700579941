import { PdfStyle } from "../PdfStyle";

export function getNumberCell({ value }: { value: number }): {
	text: string;
	style: PdfStyle[];
} {
	const obj = {
		text: value.toLocaleString(),
		style: [PdfStyle.AlignmentRight, PdfStyle.Bold],
	};

	if (value < 0) {
		obj.style.push(PdfStyle.ColorRed);
	}

	return obj;
}
