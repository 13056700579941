import * as pdfMake from "pdfmake/build/pdfmake";
import { pdfFonts } from "../../moo-config";
import { PdfStyle } from "../PdfStyle";
import { useGetIncomeStatementSheetBodyAndProfitAndLoss } from "./useGetIncomeStatementSheetBodyAndProfitAndLoss";
import { usePdfSubTitle } from "./usePdfSubTitle";

export function useOnIncomeStatementSheetButtonClick() {
	const getIncomeStatementSheetBodyAndTotalSum = useGetIncomeStatementSheetBodyAndProfitAndLoss();
	const pdfSubTitle = usePdfSubTitle();

	return async () => {
		const { body } = await getIncomeStatementSheetBodyAndTotalSum();

		pdfMake
			.createPdf(
				{
					content: [
						{
							text: "睦穆家具設計有限公司-損益表",
							fontSize: 20,
							style: PdfStyle.AlignmentCenter,
						},
						pdfSubTitle,
						{
							table: {
								headerRows: 1,
								widths: ["auto", "*", "auto", "auto", "auto", "auto"],
								body,
							},
							layout: {
								hLineWidth() {
									return 0;
								},
								vLineWidth() {
									return 0;
								},
							},
						},
					],
					images: {},
					styles: {
						[PdfStyle.AlignmentLeft]: {
							alignment: "left",
						},
						[PdfStyle.AlignmentRight]: {
							alignment: "right",
						},
						[PdfStyle.AlignmentCenter]: {
							alignment: "center",
						},
						[PdfStyle.ColorRed]: {
							color: "red",
						},
						[PdfStyle.Bold]: {
							bold: true,
						},
						[PdfStyle.MarginLeft]: {
							margin: [10, 0, 0, 0],
						},
						[PdfStyle.MarginFarLeft]: {
							margin: [20, 0, 0, 0],
						},
					},
					defaultStyle: {
						columnGap: 15,
						fontSize: 10,
						font: "chineseFont",
					},
				},
				undefined,
				pdfFonts,
			)
			.download("moos-損益表.pdf");
	};
}
