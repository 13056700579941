import { PdfStyle } from "../PdfStyle";
import { useGetAccountTypeNames } from "./useGetAccountTypeNames";
import { useGetSum } from "./useGetSum";

type IdNumber = {
	value: string;
	bodyIndex?: number;
	textIndent?: boolean;
};

export function useGetBodyAndTotalSum() {
	const getSum = useGetSum();
	const getAccountTypeNames = useGetAccountTypeNames();

	return async ({
		id_numbers,
		isReverseSum = false,
	}: {
		id_numbers: IdNumber[];
		isReverseSum?: boolean;
	}): Promise<{ body: any[][]; totalSum: number }> => {
		const accountTypeNames = await getAccountTypeNames({
			id_numbers: id_numbers.map((e) => e.value),
		});

		const body: any[][] = [];
		let totalSum = 0;
		for (const accountType of accountTypeNames.account_types) {
			const id_number = accountType.id_number;
			const idNumberObject = id_numbers.find((e) => e.value === id_number);
			if (idNumberObject) {
				let sum = (await getSum({ id_number })).sum;
				if (isReverseSum) {
					sum = -sum;
				}
				const sumCell = {
					text: sum === 0 ? "0" : sum.toLocaleString(),
					style: [PdfStyle.AlignmentRight],
				};
				if (sum < 0) {
					sumCell.style.push(PdfStyle.ColorRed);
				}
				totalSum += sum;

				const arr = [
					{
						text: accountType.id_number,
						style: [PdfStyle.AlignmentLeft, PdfStyle.MarginLeft],
					},
					{
						text: accountType.name,
						style: [PdfStyle.AlignmentLeft, PdfStyle.MarginLeft],
					},
					"",
					"",
					"",
					"",
				];
				if (idNumberObject.textIndent) {
					const margin: number[] = [20, 0, 0, 0];
					// @ts-ignore
					arr[0].margin = margin;
					// @ts-ignore
					arr[1].margin = margin;
				}

				arr[idNumberObject.bodyIndex || 3] = sumCell;
				body.push(arr);
			}
		}

		return { body, totalSum };
	};
}
