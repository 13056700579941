import { PdfStyle } from "../PdfStyle";

export function updateBodyRatio({
	bodyData,
	totalSum,
	textIndex = 2,
	ratioIndex = 4,
}: {
	bodyData: any[][];
	totalSum: number;
	textIndex?: number;
	ratioIndex?: number;
}) {
	for (const body of bodyData) {
		const text = body[textIndex].text as string;
		const number = Number(text.replace(/,/g, ""));
		const ratio = (number / totalSum) * 100;
		body[ratioIndex] = {
			text: number === 0 ? "0%" : ratio.toFixed(2) + "%",
			style: [PdfStyle.AlignmentRight],
		};

		if (number < 0) {
			body[ratioIndex].style.push(PdfStyle.ColorRed);
		}
	}
}
