import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { Permission, usePermissions } from "../../moo-me-provider";
import { useOnIncomeStatementSheetButtonClick } from "./useOnIncomeStatementSheetButtonClick";

export function IncomeStatementSheetPdfButton() {
	const permissions = usePermissions();
	const onButtonClick = useOnIncomeStatementSheetButtonClick();

	if (!permissions.includes(Permission.IncomeStatementSheetPdfDownload)) return null;

	return (
		<Tooltip title="下載損益表" onClick={onButtonClick}>
			<IconButton>
				<BsFileEarmarkPdf style={{ color: "red", fontSize: "28px", height: "100%", cursor: "pointer" }} />
			</IconButton>
		</Tooltip>
	);
}
