import { PdfStyle } from "../PdfStyle";

export function getRatioCell({ sum, totalSum }: { sum: number; totalSum: number }): {
	text: string;
	style: PdfStyle[];
} {
	const text = sum === 0 ? "0%" : ((sum / totalSum) * 100).toFixed(2) + "%";
	const cell = {
		text,
		style: [PdfStyle.AlignmentRight, PdfStyle.Bold],
	};

	if (sum < 0) {
		cell.style.push(PdfStyle.ColorRed);
	}

	return cell;
}
