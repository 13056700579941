import { PdfStyle } from "../PdfStyle";

export function getHeader({ code, name, sum }: { code: string; name: string; sum: number }) {
	const header: any[] = [
		{
			text: code,
			style: [PdfStyle.AlignmentLeft, PdfStyle.Bold],
		},
		name,
		"",
		"",
		{
			text: sum === 0 ? "0" : sum.toLocaleString(),
			style: [PdfStyle.AlignmentRight, PdfStyle.Bold],
		},
		"",
	];

	if (sum < 0) {
		if (header.length >= 3 && header[2].style) {
			header[2].style.push(PdfStyle.ColorRed);
		} else if (header.length >= 4 && header[3].style) {
			header[3].style.push(PdfStyle.ColorRed);
		}
	}

	return header;
}
