import { PdfStyle } from "../PdfStyle";
import { useGetAccountTypeNames } from "./useGetAccountTypeNames";
import { useGetSum } from "./useGetSum";

export function useGetPdfHeaderAndBody() {
	const getSum = useGetSum();
	const getAccountTypeNames = useGetAccountTypeNames();

	return async ({
		headerIdNumber,
		headerName,
		id_numbers,
		isReverseSum = false,
	}: {
		headerIdNumber: string;
		headerName: string;
		id_numbers: string[];
		isReverseSum?: boolean;
	}): Promise<{ header: string[]; body: any[][]; totalSum: number }> => {
		const accountTypeNames = await getAccountTypeNames({
			id_numbers,
		});

		const body: any[][] = [];
		let totalSum = 0;
		for (const accountType of accountTypeNames.account_types) {
			let sum = (await getSum({ id_number: accountType.id_number })).sum;
			if (isReverseSum) {
				sum = -sum;
			}
			const sumCell = {
				text: sum === 0 ? "0" : sum.toLocaleString(),
				style: [PdfStyle.AlignmentRight],
			};
			if (sum < 0) {
				sumCell.style.push(PdfStyle.ColorRed);
			}
			totalSum += sum;
			body.push([
				{
					text: accountType.id_number,
					style: [PdfStyle.AlignmentLeft, PdfStyle.MarginLeft],
				},
				{
					text: accountType.name,
					style: [PdfStyle.AlignmentLeft, PdfStyle.MarginLeft],
				},
				sumCell,
				"",
				"",
			]);
		}

		const header: any[] = [
			{
				text: headerIdNumber,
				style: [PdfStyle.AlignmentLeft, PdfStyle.Bold],
			},
			headerName,
			{
				text: totalSum === 0 ? "0" : totalSum.toLocaleString(),
				style: [PdfStyle.AlignmentRight, PdfStyle.Bold],
			},
			"",
			"",
		];

		if (totalSum < 0) {
			header[2].style.push(PdfStyle.ColorRed);
		}
		return { header, body, totalSum };
	};
}
